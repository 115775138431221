<template>
  <div class="quick-question">
    <h2 class="title-question fs-24 mb-0 font-weight-bold">
      Frequently asked questions
    </h2>
    <div class="content-question mx-auto text-left">
      <template v-for="(data, index) in questions">
        <div
          :key="data.id"
          class="d-flex justify-content-between align-items-center item"
        >
          <p class="mb-0" v-html="data.question"></p>
          <img
            v-b-toggle="data.id"
            @click="active(index)"
            :src="
              data.isActive
                ? require('@/assets/images/icons/ic_minus.svg')
                : require('@/assets/images/icons/ic_plus.svg')
            "
          />
        </div>
        <hr :key="index" />
        <b-collapse :id="data.id" :key="'answer-' + index">
          <p class="quick-answer" v-html="data.answer"></p>
        </b-collapse>
      </template>
    </div>
    <button @click="scrollToTop" class="btn-sell">SELL MY CAR</button>
  </div>
</template>

<script>
import constants from "@/config/constants";

export default {
  name: "QuickQuestion",
  data() {
    return {
      questions: constants.QUESTIONS,
    };
  },
  methods: {
    active(num) {
      this.questions[num].isActive = !this.questions[num].isActive;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
.quick-question {
  padding-bottom: 50px;
  background-color: #dfeae8;

  .btn-sell{
    width: 159px;
    height: 51px;
    background-color: #2bb673;
    border: none;
    outline: none;
    color: white;
    border-radius: 8px !important;
    margin-top: 50px;
    font-size: 15px;
    &:hover {
      background-color: #20a766;
    }
  }

  .title-question {
    line-height: 28px;
    padding-top: 75px;
    padding-bottom: 40px;
  }

  .content-question {
    max-width: 414px;
    padding: 0 10px;

    .item {
      min-height: 60px;

      p {
        color: #637380;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        transform: rotate(90deg);
      }
    }

    hr {
      border-color: #404040;
      margin-top: 0;
      margin-bottom: 0;
    }

    .quick-answer {
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 41px;
    }
  }
}

@media screen and(min-width: 768px) {
  .title-question {
    font-size: 36px !important;
    line-height: 34px !important;
    padding-top: 66px !important;

  }

  .content-question {
    max-width: 600px !important;
    padding: 0 !important;

    .item p {
      line-height: 20px !important;
      font-size: inherit !important;
    }

    .quick-answer {
      font-size: inherit !important;
    }
  }
}
</style>
