<template>
  <div v-if="['top-page', 'thanks-page'].includes(this.$route.name)" class="text-center">
    <div class="footer d-flex">
      <div class="top-footer d-flex pt-10 pb-8 px-4">
        <div class="footer-logo d-flex align-items-center justify-content-center my-2">
          <img
            src="@/assets/images/logo_footer_desktop.png"
            @click="logoFooter"
            class="cursor-pointer"
            :style="isMobile ? 'display: none' : ''"
          />
          <img
            v-if="isMobile"
            src="@/assets/images/logo_footer_mobile.png"
            @click="logoFooter"
            class="cursor-pointer"
          />
        </div>
        <div class="footer-content d-flex">
          <div class="first-content mt-2">
            <div class="logo-description">
              <p class="text-sm text-center mb-4">
                AutoFlip connects private sellers of cars with our large trusted wholesale buying network, Australia-wide.
              </p>
              <a href="mailto:info@autoflip.com.au" class="text-sm">info@autoflip.com.au</a><span style="padding-left: 15px;"></span>
              <a href="tel:1300816821" class="text-sm">1300 816 821</a>
            </div>
            <div class="social-media-container">
              <a href="https://www.facebook.com/autoflipaustralia" target="_blank">
                <img
                  src="@/assets/images/social_media_fb.png"
                  class="cursor-pointer"
                />
              </a>
              <a href="https://www.linkedin.com/company/autoflip-australia" target="_blank">
                <img
                  src="@/assets/images/social_media_in.png"
                  class="cursor-pointer"
                />
              </a>
              <a href="https://www.instagram.com/autoflipau" target="_blank">
                <img
                  src="@/assets/images/social_media_ins.png"
                  class="cursor-pointer"
                />
              </a>
              <a href="https://www.twitter.com/AutoflipAU" target="_blank">
                <img
                  src="@/assets/images/social_media_twitter.png"
                  class="cursor-pointer"
                />
              </a>
              <a href="https://www.youtube.com/@autoflip6451" target="_blank">
                <img
                  src="@/assets/images/social_media_youtube.png"
                  class="cursor-pointer"
                />
              </a>
            </div>
          </div>
          <div class="middle-content">
            <div class="google-review">
              <a
                href="https://www.google.com/search?q=autoflip&rlz=1C1GCEA_enAU1009AU1010&oq=auto&aqs=chrome.1.69i60j69i59l2j69i57j69i60l2j69i65j69i60.2288j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad643c9cf26ce91:0x52eb8c8ec5ff06e6,1,,,"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://embedsocial.com/api/source_image_badges/svg-badge-1-g/en/google/52337"
                  class="cursor-pointer mb-2"
                />
              </a>
            </div>
            <a href="https://search.google.com/local/writereview?placeid=ChIJkc4mz8lD1moR5gb_xY6M61I" target="_blank" class="text-sm">Leave a review on Google</a>
          </div>
          <div class="last-content">
            <div class="links-to-website">
              <a :href="`${autoFlipUrl}/how-it-works`" class="text-sm">How it works</a>
              <a :href="`${autoFlipUrl}/about`" class="text-sm">About us</a>
              <a :href="`${autoFlipUrl}/dealers`" class="text-sm">For dealers</a>
              <a :href="`${autoFlipUrl}/faqs`" class="text-sm">FAQ</a>
              <a :href="`${autoFlipUrl}/enterprise`" class="text-sm">For enterprise</a>
              <a :href="`${autoFlipUrl}/contact`" class="text-sm">Contact</a>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-footer d-flex">
        <a :href="`${autoFlipUrl}/terms-and-conditions`" class="text-sm py-2 px-2" style="cursor: pointer;">Terms and conditions</a><span style="width: 10px;"></span>
        <a :href="`${autoFlipUrl}/privacy-policy`" class="text-sm py-2 px-2" style="cursor: pointer;">Privacy Policy</a>
        <span class="px-4 py-2 text-sm">
          ABN: 87 638 825 751
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTOFLIP_URL } from "../../helper/constant";
export default {
  name: "Footer",
  data() {
    return {
      autoFlipUrl: AUTOFLIP_URL,
      isMobile: true,
    };
  },
  computed: {
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 1024px)");
    },
  },
  methods: {
    logoFooter() {
      window.location.href = AUTOFLIP_URL;
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>

<style lang="scss">
.text-sm {
  font-family: "Avenir Next LT W05 Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #414141 !important;
}
.footer {
  flex-direction: column;
}
.top-footer {
  flex-direction: column;
}
.footer-content {
  flex-direction: column;
  align-items: center;
}
.first-content {
  .logo-description {
    max-width: 20rem;
    a {
      font-weight: 400 !important;
      line-height: 24px !important;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .social-media-container {
    margin: 2.5rem 0;
  }
}
.middle-content {
  margin-top: 0.5rem;
  a {
    font-weight: 400 !important;
    line-height: 24px !important;
    font-size: 16px !important;
    text-decoration: underline;
    color: #6F6F6F !important;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: rgb(31 130 82) !important;
    }
  }
}
.last-content {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  .links-to-website {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 358px;
    a {
      font-weight: 400 !important;
      padding: 0 10px;
      &:hover {
        color: rgb(31 130 82) !important;
      }
    }
  }
}
.bottom-footer {
  flex-wrap: wrap;
  justify-content: center;
  border-top: 2px solid #F3F4F6;
  padding: 0.5rem;
  a, span {
    font-weight: 400 !important;
    color: #6F6F6F !important;
  }
  a:hover {
    color: rgb(31 130 82) !important;
  }
}
@media screen and (min-width: 1024px) {
  .top-footer {
    width: 72rem !important;
  }
  .footer-logo {
    align-items: flex-start !important;
    justify-content: unset !important;
  }
  .footer-content {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: unset !important;
    .first-content {
      width: 264px;
      .logo-description {
        text-align: left !important;
        margin-bottom: 2.5rem !important;
        p {
        text-align: left !important;
        }
      }
      .social-media-container {
        margin-top: unset !important;
        margin-bottom: 3rem !important;
      }
    }
    .middle-content {
      text-align: left;
      margin-top: unset !important;
      a {
        font-weight: 500 !important;
        line-height: 25px !important;
        font-size: 14px !important;
        text-decoration: underline;
        color: #6F6F6F !important;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: rgb(31 130 82) !important;
        }
      }
    }
    .last-content {
      margin: unset !important;
      text-align: left !important;
      width: 374px;
      .links-to-website {
        a {
          font-weight: 500 !important;
          line-height: 25px !important;
          padding: unset !important;
          &:hover {
            color: rgb(31 130 82) !important;
          }
        }
      }
    }
  }
  .bottom-footer {
    padding: 1rem !important;
    a, span {
      font-weight: 500 !important;
      line-height: 25px !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .top-footer {
    padding-top: 2rem;
    margin: 0 auto;
  }
}
</style>
