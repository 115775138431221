<template>
  <div class="info">
    <h2 class="title-info font-weight-bold">
      <b class="easy-step-txt">QUICK. </b>
      <b class="easy-step-txt-black">SIMPLE. </b>
      <b class="easy-step-txt">EASY.</b>
    </h2>
    <div class="type-info">
      <div class="item mt-32 d-flex align-items-center">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/enter_car_details.svg" class="img" />
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>ENTER CAR DETAILS</b>
          </p>
          <!-- DMV-188 -->
          <p class="desc-item">
            Submit your car details, and our friendly AutoFlip team will be in touch to confirm before it's visible to buyers.
          </p>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center flex-row-reverse">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/best_price_offer.svg" class="img ml-20" />
        </div>
        <div class="text-section ml-0">
          <p class="title-item mb-0 font-weight-bold">
            <b>BEST PRICE OFFER</b>
          </p>
          <!-- DMV-188 -->
          <p class="desc-item">
            Quickly receive the highest bid from our large network of dealers and wholesale buyers.
          </p>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center">
        <div class="img-section">
          <img src="@/assets/images/easy-steps/get_cash_quickly.svg" class="img" />
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>GET CASH QUICKLY</b>
          </p>
          <!-- DMV-188 -->
          <p class="desc-item">
            Accept the highest bid, have your car picked up, and get quick payment into your bank account right away. 
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
};
</script>
<style lang="scss">
.info {
  background-color: #f0f0f0;
  padding: 61px 0 69px;
  max-width: 389px;
  margin: 0 auto;

  .title-info {
    line-height: 28px;
    font-size: 26px;
    .easy-step-txt {
      color: #2BB673;
      font-size: 26px;
    }
    .easy-step-txt-black {
      font-size: 26px;
    }
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-32 {
    margin-top: 32px;
  }

  .type-info {
    .item {
      padding: 0 27px 0 35px;

      .triangle {
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(calc(-50% - 45px));
        border-left: 30px solid #90D2B1;
        border-top: 30px solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 30px solid transparent;
        display: none;
      }
    }

    .text-section {
      text-align: left;
      .title-item {
        line-height: 24px;
        margin-top: 25px;
        font-size: 18px;
        @media screen and (max-width: 320px) {
          font-size: 14px;
        }
      }
      
      .desc-item {
        line-height: 22px;
        margin: 3px 0 0 0;
        font-size: 14px;
        font-family: 'Avenir Next LT W05 Regular';
        color: #404040;
        @media screen and (max-width: 320px) {
          font-size: 12px;
        }
      }
    }

    .img-section {
      .img {
        width: 120px;
        height: 154px;

        @media screen and (max-width: 375px) {
          width: 100px;
          height: 134px;
        }

        @media screen and (max-width: 320px) {
          width: 80px;
          height: 114px;
        }
      }
    }
  }
}

@media screen and(min-width: 768px) {
  .info {
    width: 90%;
    max-width: 1084px;
    max-height: 100vh;
    padding: 30px 0;
    margin-bottom: 48px;

    .title-info {
      font-size: 36px !important;
      line-height: 36px !important;
      margin: 40px 0;
      .easy-step-txt {
        font-size: 36px;
      }
      .easy-step-txt-black {
        font-size: 36px;
      }
    }

    .type-info {
      display: flex;
      justify-content: space-between;

      .item {
        width: calc(33.33333% - 15px);
        position: relative;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        padding: 0;

        .triangle {
          display: block !important;
        }

        .desc-item {
          max-width: 260px;
          margin-top: 10px;
        }

        &:nth-child(2) {
          .desc-item {
            max-width: 250px;
          }
        }
      }

      .text-section {
        text-align: center;
        margin-left: 0 !important;

        .title-item {
          font-size: 20px !important;
        }
      }

      .img-section {
        .img {
          width: 150px;
          height: 184px;
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>