<template>
  <div class="reason-info">
    <div class="top-content-info">
      <h2 class="fs-20 mb-26 lh-24 font-weight-bold reason-info-title">
        Why use AutoFlip to
        sell your car?
      </h2>
    </div>
    <div class="bottom-content-info px-2">
      <b-row class="mobile-cards">
        <b-col v-for="(icon, index) of icons" :key="index" cols="12">
          <ReasonInfoCard :icon="icon" :title="titles[index]" :subtitles="subtitles[index]" :index="index" />
        </b-col>
      </b-row>
      <b-row class="desktop-cards">
        <b-col v-for="(icon, index) of icons" :key="index" cols="6" class="p-3">
          <ReasonInfoCardDesktop :icon="icon" :title="titles[index]" :subtitles="subtitles[index]" />
        </b-col>
      </b-row>
      <button @click="scrollToTop" class="btn-sell">SELL MY CAR</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyUse",
  components: {
    ReasonInfoCard: () => import('./ReasonInfoCard.vue'),
    ReasonInfoCardDesktop: () => import('./ReasonInfoCardDesktop.vue'),
  },
  data() {
    return {
      icons: ["best_price", "trusted", "easy", "private_sales"],
      titles: ["Best price, quick sale", "Trusted assistance and support  ", "Simple and easy", "Avoid the hassle of private sales"],
      subtitles: [
        ["Our large network of dealers compete to offer the best price", "Get paid for your car quickly", "Free to advertise your car to our dealer network"],
        ["Our customer service team works to get you the best price", "No need to stress about negotiating with dealers - we'll do it for you", "Our friendly team is available via phone or email for assistance"],
        ["List your car easily through our simple, guided steps", 'Contactless sales - sell your car from the comfort of your own home'],
        ['Safe, secure and no hassle - avoid having strangers coming to your home', 'Sell your car to serious buyers only', 'No roadworthy needed - avoid the effort & cost of getting certified'],
      ]
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style lang="scss">
.reason-info {
  background-color: #E0E2DE;

  .top-content-info {
    text-align: center;
    padding-top: 75px;

    .last-answer {
      color: #4d4d4d;
      margin-bottom: 46px;
    }
  }

  .bottom-content-info {
    padding-bottom: 50px;
    max-width: 375px;
    margin: 0 auto;
    .desktop-cards {
      display: none;
    }
    .btn-sell{
      width: 159px;
      height: 51px;
      background-color: #545454;
      border: none;
      outline: none;
      color: white;
      border-radius: 8px !important;
      margin-top: 35px;
      font-size: 15px;
      &:hover {
        background-color: #3b3b3b;
      }
    }
  }
}

@media screen and(min-width: 1200px) {
  .top-content-info {
    padding-top: 50px !important;
    .reason-info-title {
      font-size: 36px;
      line-height: 34px;
      margin-bottom: 48px;
    }
  }

  .bottom-content-info {
    max-width: 1200px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .mobile-cards {
      display: none !important;
    }
    .desktop-cards {
      display: flex !important;
    }
    .btn-sell {
      font-size: inherit !important;
    }
  }
}
</style>
