<template>
  <div>
    <div class="top-page-content">
      <rego-search></rego-search>
      <car-info></car-info>
      <why-use></why-use>
      <testimonial></testimonial>
      <car-sale></car-sale>
      <quick-question class="pb-50"></quick-question>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import RegoSearch from "@/components/top-page/RegoSearch";
import CarInfo from "@/components/top-page/Info";
import CarSale from "@/components/top-page/CarSale";
import QuickQuestion from "@/components/top-page/QuickQuestion";
import FooterView from "@/components/layout/Footer";
import WhyUse from "../../components/top-page/WhyUse.vue";
import Testimonial from "@/components/top-page/Testimonial.vue";

export default {
  name: "Index",
  components: {
    RegoSearch,
    CarInfo,
    CarSale,
    QuickQuestion,
    FooterView,
    WhyUse,
    Testimonial,
  },
};
</script>

<style lang="scss">
.top-page-content {
  color: #404040;
  text-align: center;
  background-color: #efefef;
  overflow-x: hidden;
}
</style>
