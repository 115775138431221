<template>
  <div class="testimonial">
    <h2 class="fs-24 testimonial-title mb- font-weight-bold">Don't take our word for it</h2>
    <div class="position-relative testimonial-content mx-auto mt-5">
      <iframe style="border: 0; width: 100%; height: 330px;" scrolling="no" src="https://embedsocial.com/api/pro_hashtag/55bd3037b60eedcdc1f26d2c29fa9311991ac315"></iframe>
    </div>
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import constants from '@/config/constants'

export default {
  name: "Testimonial",
  data() {
    return {
      testimonial: constants.TESTIMONIAL,
      isMobile: true,
    }
  },
  methods: {
    showPrev() {
      this.$refs.slide.prev()
    },
    showNext() {
      this.$refs.slide.next()
    }
  },
  computed: {
    mobileMediaQuery: function() { 
      return window.matchMedia('(min-width: 1200px)');
    },
    settings: function() {
      return {
        "dots": true,
        "dotsClass": "slick-dots custom-dots",
        "arrows": false,
        "focusOnSelect": true,
        "swipeToSlide": true,
        "infinite": true,
        "speed": this.isMobile ? 500 : 800,
        "slidesToShow": this.isMobile ? 1 : 3,
        "slidesToScroll": 1
      }
    },
  },
  mounted() {
    this.mobileMediaQuery.addListener(e => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
}
</script>

<style lang="scss">
.testimonial {
  padding-bottom: 120px;

  .testimonial-title {
    line-height: 28px;
    padding-top: 60px;
  }

  .testimonial-content {
    max-width: 389px;

    .testimonial-slide {
      max-width: 307px;

      .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          max-width: 311px;
        }

        .item {
          width: fit-content;
          height: 485px;
          background-color: #FFFFFF;
          border-radius: 10px;
          
          .ic-leftquotation {
            padding: 20px 15px 10px 15px;
          }

          .img {
            width: 287px;
            height: 160px;
            background-color: #DADADA;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          .testimonial-info {
            padding: 0 20px 0;

            .dash {
              background-color: #2bb673;
              height: 3px;
              width: 17px;
              margin: 2px 10px 0 0;
            }

            .testimonial-name {
                color: #2bb673;
            }

            .ic-rightquotation {
              margin-right: 0;
              margin-left: auto;
              display:block;
              margin-bottom: 19px;
            }
          }
        }
      }

      .custom-dots {
        bottom: -79px;
        line-height: 0;

        li {
          width: 10px;
          margin: 0 10px;

          button {
            width: 10px;

            &:before {
              font-size: 10px;
              width: 10px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }

        li.slick-active {
          button {
            &:before {
              color: #00BB65;
            }
          }
        }
      }
    }
  }

  .icon-prev, .icon-next {
    width: 21px;
    height: 42px;
  }

  .icon-prev {
    left: 0;
  }

  .icon-next {
    right: 0;
  }
}

@media screen and(min-width: 768px) {
  .testimonial-title {
    font-size: 36px;
    line-height: 34px;
  }

  .testimonial-content {
    max-width: 900px !important;

    .testimonial-slide {
      max-width: 780px !important;

      .slick-slide {
        div {
          max-width: 375px !important;
        }

        .ic-rightquotation {
          margin-bottom: 5px !important;
        }
      }
    }
  }
}

@media screen and(min-width: 1200px) {
  .testimonial-content {
    max-width: 1308px !important;

    .testimonial-slide {
      max-width: 1228px !important;
    
      .item {
        height: 544px !important;

        .testimonial-info {
          padding: 0 24px 0 40px !important;
      
          .testimonial-feedback {
            font-size: 20px !important;
          }

          .testimonial-name {
            font-size: 33px;
          }
        }
      }
    }
  }
}
</style>
